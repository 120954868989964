import { render, staticRenderFns } from "./Blockchain.vue?vue&type=template&id=28621bfe&scoped=true&"
import script from "./Blockchain.vue?vue&type=script&lang=js&"
export * from "./Blockchain.vue?vue&type=script&lang=js&"
import style0 from "./Blockchain.vue?vue&type=style&index=0&id=28621bfe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28621bfe",
  null
  
)

export default component.exports